import { menu } from './menu';
import { swup } from './swup';
import { filterAndSort } from './filter-and-sort';
import { accordion } from './accordion';

const initializeApp = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Environment:', process.env.NODE_ENV);
  }

  const swupInstance = swup();
  menu();
  filterAndSort(swupInstance);
  accordion(swupInstance);
};

// Simple DOM ready check
const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

rdy(initializeApp);
