import type Swup from 'swup';

type URLModifier = (url: URL) => void;

/**
 * Sets up event filtering functionality with preloading
 * @param swup Swup instance for page transitions
 */
export function filterAndSort(swup: Swup) {
  const initialize = () => {
    initializeFilters(swup);
    initializeSort(swup);
  };

  // Initialize on first load and after content replacement
  initialize();
  swup.hooks.on('content:replace', initialize);
}

function updateUrlAndNavigate(swup: Swup, modifier: URLModifier): void {
  const url = new URL(window.location.href);
  modifier(url);
  // prevent scroll to top
  swup.scrollTo = () => {};
  swup.navigate(url.toString(), {
    history: 'replace',
    animate: false,
  });
}

function initializeFilters(swup: Swup) {
  const container = document.querySelector<HTMLElement>('#filter-container');
  if (!container) return;

  const preloadedUrls = new Set<string>();

  // Handle filter changes
  container.addEventListener('change', (e) => {
    const radio = e.target as HTMLInputElement;
    if (radio.type !== 'radio') return;

    updateUrlAndNavigate(swup, (url) => {
      radio.value ? url.searchParams.set('cat', radio.value) : url.searchParams.delete('cat');
    });
  });

  // Handle preloading
  container.addEventListener('mouseover', (e) => {
    if (!swup.preload) return;

    const label = (e.target as HTMLElement).closest('label');
    if (!label) return;

    const radio = document.getElementById(label.htmlFor) as HTMLInputElement | null;
    if (!radio) return;

    const url = new URL(window.location.href);
    radio.value ? url.searchParams.set('cat', radio.value) : url.searchParams.delete('cat');
    const urlString = url.toString();

    if (preloadedUrls.has(urlString)) return;

    preloadedUrls.add(urlString);
    swup.preload(urlString).catch(() => {
      // Silently fail preload
    });
  });
}

function initializeSort(swup: Swup) {
  const sort = document.querySelector<HTMLSelectElement>('select[name="sort"]');
  if (!sort) return;

  sort.addEventListener('change', (e) => {
    const value = (e.target as HTMLSelectElement).value;
    updateUrlAndNavigate(swup, (url) => {
      value === 'date' ? url.searchParams.delete('sort') : url.searchParams.set('sort', value);
    });
  });
}
