import type { Swup } from 'swup';

export const accordion = (swup: Swup) => {
  initializeAccordion();
  swup.hooks.on('content:replace', initializeAccordion);
};

const initializeAccordion = () => {
  const details = document.querySelectorAll<HTMLDetailsElement>('details');
  if (!details.length) return;

  const EASING = 'cubic-bezier(0.4, 0, 0.2, 1)';

  const animate = (element: Element, keyframes: Keyframe[], duration: number) =>
    element.animate(keyframes, { easing: EASING, duration, fill: 'forwards' });

  const calculateDuration = (height: number) => Math.min(400, Math.max(200, (Math.abs(height) / 24) * 100));

  details.forEach((detail) => {
    const summary = detail.querySelector('summary');
    const content = detail.querySelector('div');
    if (!summary || !content) return;

    summary.setAttribute('role', 'button');
    summary.setAttribute('aria-expanded', 'false');
    content.setAttribute('role', 'region');
    content.setAttribute('aria-hidden', 'true');

    let heightAnimation: Animation | null = null;
    let fadeAnimation: Animation | null = null;

    const animateContent = (isOpen: boolean) => {
      heightAnimation?.cancel();
      fadeAnimation?.cancel();

      const summaryHeight = summary.offsetHeight;
      const contentHeight = content.scrollHeight;
      const startHeight = isOpen ? summaryHeight : summaryHeight + contentHeight;
      const endHeight = isOpen ? summaryHeight + contentHeight : summaryHeight;
      const duration = calculateDuration(endHeight - startHeight);

      if (isOpen) {
        detail.setAttribute('open', '');
        content.style.opacity = '0';
      }
      detail.style.height = `${startHeight}px`;

      heightAnimation = animate(detail, [{ height: `${startHeight}px` }, { height: `${endHeight}px` }], duration);

      const fadeKeyframes = isOpen
        ? [
            { opacity: 0, transform: 'translate3d(0, -8px, 0)' },
            { opacity: 1, transform: 'translate3d(0, 0, 0)' },
          ]
        : [
            { opacity: 1, transform: 'translate3d(0, 0, 0)' },
            { opacity: 0, transform: 'translate3d(0, -8px, 0)' },
          ];

      if (isOpen) {
        heightAnimation.onfinish = () => {
          detail.style.height = '';
        };
        // overlap fade animation with height animation
        setTimeout(() => {
          fadeAnimation = animate(content, fadeKeyframes, 150);

          fadeAnimation.onfinish = () => {
            content.style.opacity = '';
            content.style.transform = '';
            summary.setAttribute('aria-expanded', 'true');
            content.setAttribute('aria-hidden', 'false');
          };
        }, duration - 150);
      } else {
        fadeAnimation = animate(content, fadeKeyframes, 150);

        heightAnimation.onfinish = () => {
          detail.style.height = '';
          detail.removeAttribute('open');
          summary.setAttribute('aria-expanded', 'false');
          content.setAttribute('aria-hidden', 'true');
          content.style.opacity = '';
          content.style.transform = '';
        };
      }
    };

    summary.addEventListener('click', (e) => {
      e.preventDefault();
      if (heightAnimation?.playState === 'running' || fadeAnimation?.playState === 'running') return;
      animateContent(!detail.hasAttribute('open'));
    });
  });
};
