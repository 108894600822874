import Swup from 'swup';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import type { Plugin } from 'swup';

declare global {
  var swup: Swup;
}

const plugins: Plugin[] = [
  new SwupBodyClassPlugin(),
  new SwupScriptsPlugin(),
  new SwupPreloadPlugin(),
  new SwupScrollPlugin({
    animateScroll: {
      betweenPages: false,
      samePageWithHash: false,
      samePage: false,
    },
  }),
];

if (process.env.NODE_ENV === 'production') {
  plugins.unshift(new SwupHeadPlugin());
}

const containers = [
  '#menu ul',
  'main',
  // if admin bar is present, add it to the containers
  document.body.classList.contains('admin-bar') ? '#wpadminbar' : null,
].filter((x): x is string => Boolean(x));

const notSelectors = [
  '[href*="/wp-admin"]', // Exclude admin pages
  '[href*=".pdf"]', // Exclude PDF links
  '[target="_blank"]', // Exclude links that open in new tabs
]
  .map((selector) => `:not(${selector})`)
  .join('');

const linkSelector = [
  `a[href^="${window.location.origin}"]${notSelectors}`, // same origin
  `a[href^="/"]${notSelectors}`, // or pathname
].join(',');

export const swup = (): Swup => {
  if (window.swup) return window.swup;

  const instance = new Swup({
    containers,
    linkSelector,
    animationSelector: false, // disable animation
    plugins,
  });

  // Store original scrollTo function
  const originalScrollTo = instance.scrollTo;

  // Reset scrollTo function after visit ends
  instance.hooks.on('visit:end', () => {
    setTimeout(() => (instance.scrollTo = originalScrollTo));
  });

  return (window.swup = instance);
};
